import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import { addLocaleData } from 'react-intl';
import nlLocaleData from 'react-intl/locale-data/nl';
import { reducer as reduxFormReducer } from 'redux-form';
import user from 'reducers/user';
import common from 'reducers/common';
import customerJourney from 'reducers/customerJourney';
import dashboard from 'reducers/dashboard';
import overview from 'reducers/overview';
import localesReducer from 'reducers/language';
import admin from 'reducers/admin';
import nlMessages from './i18n/nl.json';

addLocaleData([...nlLocaleData]);
const initialState = {
  intl: {
    defaultLocale: 'nl',
    locale: 'nl',
    messages: nlMessages,
  },
};

const rootReducer = combineReducers({
  locales: localesReducer,
  intl: intlReducer,
  form: reduxFormReducer,
  user,
  common,
  customerJourney,
  dashboard,
  overview,
  admin,
});

export default createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk)),
);
