export const GET_NIBUD_CATEGORIES_SUCCESS = 'GET_NIBUD_CATEGORIES_SUCCESS';
export const GET_API_USER_SUCCESS = 'GET_API_USER_SUCCESS';
export const GET_SCENARIO_ID_SUCCESS = 'GET_SCENARIO_ID_SUCCESS';
export const GET_PERSONS_SUCCESS = 'GET_PERSONS_SUCCESS';
export const GET_CARS_SUCCESS = 'GET_CARS_SUCCESS';
export const GET_CHILDREN_SUCCESS = 'GET_CHILDREN_SUCCESS';
export const GET_INCOME_SUCCESS = 'GET_INCOME_SUCCESS';
export const GET_INCOME_TYPES_SUCCESS = 'GET_INCOME_TYPES_SUCCESS';
export const UPLOAD_TAX_SUCCESS = 'UPLOAD_TAX_SUCCESS';
export const UPLOAD_PENSION_SUCCESS = 'UPLOAD_PENSION_SUCCESS';
export const SEND_CJ1_DATA_SUCCESS = 'SEND_CJ1_DATA_SUCCESS';
export const SEND_NIBUD_CATEGORIES_SUCCESS = 'SEND_NIBUD_CATEGORIES_SUCCESS';
