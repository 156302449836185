// @flow
import { combineReducers } from 'redux';
import * as types from 'action-types/overview';
import * as config from 'utils/config';
import * as dashboardTypes from 'action-types/dashboard';
import {
  roundNumber,
  isOfTypeNumber,
  isEmptyObject,
  isIterableObject,
} from 'services/utils';
import { long_life, death, partner_death } from 'reducers/mockData/overview';

export function selectedRetirementType(
  state: string = config.default.defaultRetirementType,
  action
) {
  switch (action.type) {
    case types.LOAD_SCENARIOS_SUCCESS: {
      const selectedScenarioId = parseInt(
        localStorage.getItem('scenarioId'),
        0
      );
      let selectedScenario = {};
      const scenariosLen = action.data.length;
      for (let i = 0; i < scenariosLen; i++) {
        const scenario = action.data[i];
        if (
          scenario.code === config.default.defaultScenario &&
          !selectedScenario.id
        ) {
          selectedScenario = scenario;
        }
        if (scenario.id === selectedScenarioId) {
          selectedScenario = scenario;
        }
      }
      if (selectedScenario.id) {
        return selectedScenario.retirementType;
      }
      return state;
    }
    case types.SET_RETIREMENT_TYPE:
      if (
        isEmptyObject(action.retirementType) ||
        typeof action.retirementType === 'undefined'
      ) {
        return state;
      }
      return action.retirementType;
    default:
      return state;
  }
}
export function selectedRetirementYear(
  state: Number = config.default.defaultRetirementAge,
  action
) {
  switch (action.type) {
    case types.LOAD_SCENARIOS_SUCCESS: {
      const selectedScenarioId =
        localStorage.getItem('scenarioId') || action.data[0].id;
      let activeScenario = action.data.find(
        scenario => scenario.id === selectedScenarioId
      );
      activeScenario = activeScenario || action.data[0];
      return activeScenario.retirementAge;
    }
    case types.SET_RETIREMENT_AGE:
      return action.retirementYear;
    default:
      return state;
  }
}

export function defaultRetirementAge(
  state: Number = config.default.defaultRetirementAge,
  action
) {
  switch (action.type) {
    case types.LOAD_SCENARIOS_SUCCESS: {
      const responseHasData =
        action.data && Array.isArray(action.data) && action.data.length > 0;
      if (responseHasData) {
        const selectedScenarioId =
          localStorage.getItem('scenarioId') || action.data[0].id;
        let activeScenario = action.data.find(
          scenario => scenario.id === selectedScenarioId
        );
        activeScenario = activeScenario || action.data[0];
        return activeScenario.retirementAge;
      }

      return config.default.defaultRetirementAge;
    }
    default:
      return state;
  }
}

export function isRetirementVisible(state: boolean = true, action) {
  switch (action.type) {
    case types.SET_SELECTED_SCENARIO:
      return action.scenario !== config.default.disabledRetirementScenario;
    case types.DISABLE_RETIREMENT_ACCESS:
      return false;
    default:
      return state;
  }
}

export function selectedHighLow(
  state: number = config.default.difference,
  action
) {
  switch (action.type) {
    case types.SET_HIGH_LOW:
      if (
        isOfTypeNumber(action.difference) &&
        !Array.isArray(action.difference)
      ) {
        return action.difference;
      } else {
        return config.default.difference;
      }
    default:
      if (isOfTypeNumber(state) && !Array.isArray(state)) {
        return state;
      }
      return config.default.difference;
  }
}

export function scenarios(state: Array = [], action) {
  switch (action.type) {
    case types.LOAD_SCENARIOS_SUCCESS:
      if (isIterableObject(action.data) && typeof action.data !== 'string') {
        return [...action.data];
      }
      return [];
    default:
      if (isIterableObject(state) && typeof state !== 'string') {
        return state;
      }
      return [];
  }
}

export function isCompletenessActive(
  state: Boolean = config.default.isCompletenessActive,
  action
) {
  switch (action.type) {
    case types.LOAD_SCENARIOS_SUCCESS:
      const currentScenario = localStorage.getItem('scenarioId');
      if (currentScenario && action.data) {
        let activeScenario = action.data.filter(
          scenario => scenario.id === currentScenario
        )[0];
        if (!activeScenario) {
          activeScenario = action.data.filter(
            scenario => scenario.code === config.default.defaultScenario
          )[0];
        }
        return activeScenario.code === config.default.defaultScenario;
      }
      return config.default.isCompletenessActive;
    case types.SET_SELECTED_SCENARIO:
      return action.scenario.code === config.default.defaultScenario;
    default:
      if (typeof state === 'boolean') {
        return state;
      } else {
        return config.default.isCompletenessActive;
      }
  }
}

export function monthOverview(state: Array<void> = [], action) {
  switch (action.type) {
    case types.SET_CURRENT_YEAR:
      let dataIncomes = action.currentYear.incomes.filter(
        income => income.net > 0
      );

      let dataExpenses = action.currentYear.expenses.filter(
        expense => expense.value > 0
      );

      return [...dataIncomes.reverse(), ...dataExpenses.reverse()];
    case dashboardTypes.LOAD_YEARLY_OVERVIEW_SUCCESS: {
      let firstYear = [];
      const currentYear = parseInt(localStorage.getItem('currentYear'), 0);
      if (!currentYear) {
        if (action.data[0] != null) {
          localStorage.setItem('currentYear', action.data[0].year);
        }
        firstYear = action.data[0];
      } else {
        firstYear = action.data.find(item => item.year === currentYear);
        if (!firstYear) {
          firstYear = action.data[0];
        }
      }
      let dataIncomes = firstYear.incomes.filter(income => income.net > 0);
      let dataExpenses = firstYear.expenses.filter(
        expense => expense.value > 0
      );
      return [...dataIncomes.reverse(), ...dataExpenses.reverse()];
    }
    default:
      return state;
  }
}

export function selectedCurrentYear(state: Object = {}, action) {
  switch (action.type) {
    case types.SET_CURRENT_YEAR:
      if (action.currentYear != null) {
        localStorage.setItem('currentYear', action.currentYear.year);
      }
      return action.currentYear;
    case dashboardTypes.LOAD_YEARLY_OVERVIEW_SUCCESS: {
      let selectedYear = {};
      const currentYear = parseInt(localStorage.getItem('currentYear'), 0);
      if (!currentYear) {
        selectedYear = action.data[0];
      } else {
        selectedYear =
          action.data.find(item => item.year === currentYear) || action.data[0];
      }
      localStorage.setItem('currentYear', selectedYear.year);
      return selectedYear;
    }
    case types.RESET_CURRENT_YEAR:
      localStorage.removeItem('currentYear');
      return {};
    default:
      return state;
  }
}

const defaultScenarios = [long_life, death, partner_death];

export function isChartVisible(
  state: Boolean = config.default.isChartVisible,
  action
) {
  switch (action.type) {
    case types.SET_SELECTED_SCENARIO:
      if (
        action.scenario &&
        typeof action.scenario === 'string' &&
        action.scenario !== '' &&
        defaultScenarios.includes(action.scenario)
      ) {
        return true;
      }
      return config.default.isChartVisible;
    case types.LOAD_SCENARIOS_SUCCESS: {
      if (
        action.data &&
        isIterableObject(action.data) &&
        action.data.length > 0
      ) {
        const activeScenario = action.data.filter(
          scenario => scenario.code === config.default.defaultScenario
        )[0];
        return activeScenario !== {} && activeScenario !== null;
      }
      return config.default.isChartVisible;
    }
    default:
      if (typeof state === 'boolean') {
        return state;
      }
      return config.default.isChartVisible;
  }
}

export function mortgageItem(state: Object = {}, action) {
  switch (action.type) {
    case types.MORTGAGE_LOADED_SUCCESS:
      return { ...action.data };
    default:
      return state;
  }
}

export function rentItem(state: Object = {}, action) {
  switch (action.type) {
    case types.RENT_LOADED_SUCCESS:
      return { ...action.data };
    default:
      return state;
  }
}

export function nibudItems(state: Array = [], action) {
  switch (action.type) {
    case types.NIBUD_LOADED_SUCCESS:
      if (
        action.returnData &&
        !isEmptyObject(action.returnData) &&
        action.returnData.data &&
        !isEmptyObject(action.returnData.data)
      ) {
        const roundedValues = action.returnData.data.map(currentItem => {
          currentItem.example = roundNumber(currentItem.example);
          currentItem.value = roundNumber(currentItem.value);

          return currentItem;
        });

        return [...roundedValues];
      } else {
        return [];
      }

    default:
      if (Array.isArray(state)) {
        return state;
      }
      return [];
  }
}

export function mortgageTypes(state: Array = [], action) {
  switch (action.type) {
    case types.MORTGAGE_TYPES_LOADED_SUCCESS:
      if (action.data && isIterableObject(action.data)) {
        action.data.map(mortgageType => {
          const item = mortgageType;
          item.label = mortgageType.description;
          item.value = mortgageType.id;
          item.key = mortgageType.id;
          return item;
        });
        return [...action.data];
      } else {
        return [];
      }
    default:
      if (state && Array.isArray(state)) {
        return state;
      } else {
        return [];
      }
  }
}

export function loanItem(state: Object = {}, action) {
  switch (action.type) {
    case types.LOANS_LOADED_SUCCESS:
      if (
        action.data &&
        action.data.items &&
        isIterableObject(action.data.items)
      ) {
        action.data.items.map(loanItem => {
          const item = loanItem;
          item.owner = 0;
          if (item.clientResponsable != null) {
            item.owner += 1;
          }
          if (item.partenerResponsable != null) {
            item.owner += 2;
          }
          item.amount = roundNumber(loanItem.amount);
          item.monthlyAmount = roundNumber(loanItem.monthlyAmount);
          return item;
        });
        return { ...action.data };
      } else {
        return {};
      }
    default:
      if (state === null || Array.isArray(state) || typeof state !== 'object') {
        return {};
      } else {
        return state;
      }
  }
}

export function loanTypes(state: Array = [], action) {
  switch (action.type) {
    case types.LOAN_TYPES_LOADED_SUCCESS:
      if (action.data && isIterableObject(action.data)) {
        action.data.map(loanType => {
          const item = loanType;
          item.label = loanType.description;
          item.value = loanType.id;
          item.key = loanType.id;
          return item;
        });
        return [...action.data];
      } else {
        return [];
      }
    default:
      if (state && Array.isArray(state)) {
        return state;
      } else {
        return [];
      }
  }
}

export function aowItems(state: Array = [], action) {
  switch (action.type) {
    case types.AOW_LOADED_SUCCESS:
      if (
        action.data &&
        action.data.items &&
        isIterableObject(action.data.items)
      ) {
        return [...action.data.items];
      } else {
        return [];
      }
    default:
      if (state && Array.isArray(state)) {
        return state;
      } else {
        return [];
      }
  }
}

export function pensionItem(state: Object = {}, action) {
  switch (action.type) {
    case types.PENSION_LOADED_SUCCESS:
    case types.CALCULATE_PRE_PENSION_SUCCESS:
      if (
        action.data &&
        action.data.items &&
        isIterableObject(action.data.items)
      ) {
        action.data.items.map(pension => {
          const item = pension;
          item.hasEndDate = item.endDate != null;
          item.premie = roundNumber(pension.premie);
          return item;
        });
        return action.data;
      } else {
        return {};
      }

    default:
      if (state === null || Array.isArray(state) || typeof state !== 'object') {
        return {};
      } else {
        return state;
      }
  }
}

export function pensionTypes(state: Array = [], action) {
  switch (action.type) {
    case types.PENSION_TYPES_LOADED_SUCCESS:
      if (action.data && isIterableObject(action.data)) {
        action.data.map(pensionType => {
          const item = pensionType;
          item.label = pensionType.description;
          item.value = pensionType.id;
          item.key = pensionType.id;
          return item;
        });
        return [...action.data];
      } else {
        return [];
      }
    default:
      if (state && Array.isArray(state)) {
        return state;
      } else {
        return [];
      }
  }
}

export function insuranceItem(state: Object = {}, action) {
  switch (action.type) {
    case types.LIFE_INSURANCE_LOADED_SUCCESS:
      if (
        action.data &&
        action.data.items &&
        isIterableObject(action.data.items)
      ) {
        action.data.items.map(insuranceItem => {
          const item = insuranceItem;
          item.premie = roundNumber(insuranceItem.premie);
          return item;
        });
        return action.data;
      } else {
        return {};
      }
    default:
      if (state === null || Array.isArray(state) || typeof state !== 'object') {
        return {};
      } else {
        return state;
      }
  }
}

export function insuranceTypes(state: Array = [], action) {
  switch (action.type) {
    case types.LIFE_INSURANCE_TYPES_LOADED_SUCCESS:
      action.data.map(insuranceType => {
        const item = insuranceType;
        item.label = insuranceType.description;
        item.value = insuranceType.id;
        item.key = insuranceType.id;
        return item;
      });
      return [...action.data];
    default:
      return state;
  }
}

export function insuranceTaxTypes(state: Array = [], action) {
  switch (action.type) {
    case types.LIFE_INSURANCE_TAX_TYPES_LOADED_SUCCESS:
      if (action.data && isIterableObject(action.data)) {
        action.data.map(insuranceTaxType => {
          const item = insuranceTaxType;
          item.label = insuranceTaxType.description;
          item.value = insuranceTaxType.id;
          item.key = insuranceTaxType.id;
          return item;
        });
        return [...action.data];
      } else {
        return [];
      }
    default:
      if (state && Array.isArray(state)) {
        return state;
      } else {
        return [];
      }
  }
}

export function incomeItem(state: Object = {}, action) {
  switch (action.type) {
    case types.INCOME_LOADED_SUCCESS:
      action.data.items.map(
        incomeItem => (incomeItem.income = roundNumber(incomeItem.income))
      );
      return action.data;
    default:
      return state;
  }
}

export function incomeTypes(state: Array = [], action) {
  switch (action.type) {
    case types.INCOME_TYPES_LOADED_SUCCESS:
      action.data.map(incomeType => {
        const item = incomeType;
        item.label = incomeType.description;
        item.value = incomeType.id;
        item.key = incomeType.id;
        return item;
      });
      return [...action.data];
    default:
      return state;
  }
}

export function savingsItem(state: Object = {}, action) {
  switch (action.type) {
    case types.SAVINGS_LOADED_SUCCESS:
    case types.CALCULATE_SAVINGS_SUCCESS:
      const roundedObject = { ...action.data };

      for (var objProp in roundedObject) {
        if (isOfTypeNumber(roundedObject[objProp]) && objProp !== 'id') {
          roundedObject[objProp] = roundNumber(roundedObject[objProp]);
        }
      }

      if (
        roundedObject.hasOwnProperty('items') &&
        roundedObject.items.length > 0
      ) {
        roundedObject.items = roundedObject.items.map(item => {
          item.amount = roundNumber(item.amount);
          item.grossMonthlySavings = roundNumber(item.grossMonthlySavings);

          return item;
        });
      }
      return { ...roundedObject };
    default:
      if (state === null || Array.isArray(state) || typeof state !== 'object') {
        return {};
      } else {
        return state;
      }
  }
}

export function investmentsItem(state: Object = {}, action) {
  switch (action.type) {
    case types.INVESTMENTS_LOADED_SUCCESS:
    case types.CALCULATE_INVESTMENTS_SUCCESS:
      const roundedObject = { ...action.data };

      for (var objProp in roundedObject) {
        if (isOfTypeNumber(roundedObject[objProp]) && objProp !== 'id') {
          roundedObject[objProp] = roundNumber(roundedObject[objProp]);
        }
      }

      if (
        roundedObject.hasOwnProperty('items') &&
        roundedObject.items.length > 0
      ) {
        roundedObject.items = roundedObject.items.map(item => {
          item.amount = roundNumber(item.amount);
          item.grossMonthlyInvestments = roundNumber(
            item.grossMonthlyInvestments
          );

          return item;
        });
      }

      return { ...roundedObject };
    default:
      if (state === null || Array.isArray(state) || typeof state !== 'object') {
        return {};
      } else {
        return state;
      }
  }
}

export function houseItem(state: Object = {}, action) {
  switch (action.type) {
    case types.HOUSE_LOADED_SUCCESS:
    case types.CALCULATE_HOUSE_SUCCESS:
      let roundedObject = { ...action.data };

      for (var objProp in roundedObject) {
        if (
          isOfTypeNumber(roundedObject[objProp]) &&
          objProp !== 'id' &&
          objProp !== 'retirementPercentage'
        ) {
          roundedObject[objProp] = roundNumber(roundedObject[objProp]);
        }
      }

      return { ...roundedObject };
    default:
      if (state === null || Array.isArray(state) || typeof state !== 'object') {
        return {};
      } else {
        return state;
      }
  }
}

export function inheritanceItem(state: Object = {}, action) {
  switch (action.type) {
    case types.INHERITANCE_LOADED_SUCCESS:
    case types.CALCULATE_INHERITANCE_SUCCESS:
      const roundedObject = { ...action.data };

      for (var objProp in roundedObject) {
        if (isOfTypeNumber(roundedObject[objProp]) && objProp !== 'id') {
          roundedObject[objProp] = roundNumber(roundedObject[objProp]);
        }

        if (objProp === 'items' && roundedObject[objProp].length > 0) {
          roundedObject[objProp] = roundedObject[objProp].map(item => {
            item.amount = roundNumber(item.amount);
            return item;
          });
        }
      }

      return { ...roundedObject };
    default:
      if (state === null || Array.isArray(state) || typeof state !== 'object') {
        return {};
      } else {
        return state;
      }
  }
}

export function inheritanceTypes(state: Array = [], action) {
  switch (action.type) {
    case types.INHERITANCE_TYPES_LOADED_SUCCESS:
      if (action.data && isIterableObject(action.data)) {
        action.data.map(inheritanceType => {
          const item = inheritanceType;
          item.label = inheritanceType.description;
          item.value = inheritanceType.id;
          item.key = inheritanceType.id;
          return item;
        });
        return [...action.data];
      } else {
        return [];
      }
    default:
      if (state && Array.isArray(state)) {
        return state;
      } else {
        return [];
      }
  }
}

export function houseHoldItem(state: Object = {}, action) {
  switch (action.type) {
    case types.HOUSEHOLD_LOAD_SUCCESS:
      const roundedObject = { ...action.data };
      if (roundedObject.hasOwnProperty('expense')) {
        roundedObject.expense = roundedObject.expense.map(item => {
          item.value = roundNumber(item.value);
          item.base = roundNumber(item.base);
          item.example = roundNumber(item.example);

          return item;
        });
      }

      if (
        roundedObject.hasOwnProperty('home') &&
        roundedObject.home.hasOwnProperty('items')
      ) {
        roundedObject.home.items = roundedObject.home.items.map(item => {
          item.monthlyAmount = roundNumber(item.monthlyAmount);
          item.value = roundNumber(item.value);

          return item;
        });
      }
      if (roundedObject.hasOwnProperty('rent')) {
        if (roundedObject.hasOwnProperty('amount')) {
          roundedObject.rent.amount = roundNumber(roundNumber.rent.amount);
        }
      }
      return { ...roundedObject };
    default:
      if (state === null || Array.isArray(state) || typeof state !== 'object') {
        return {};
      } else {
        return state;
      }
  }
}

export function shouldShowPrepension(state = false, action) {
  switch (action.type) {
    case types.PENSION_LOADED_SUCCESS:
    case types.CALCULATE_PRE_PENSION_SUCCESS:
      if (
        action.data &&
        !isEmptyObject(action.data) &&
        action.data.hasOwnProperty('pensionBeforeAowClient') &&
        action.data.hasOwnProperty('pensionBeforeAowPartner')
      ) {
        return (
          action.data.pensionBeforeAowClient ||
          action.data.pensionBeforeAowPartner
        );
      } else {
        return false;
      }
    default:
      if (state && typeof state === 'boolean') {
        return state;
      } else {
        return false;
      }
  }
}

export function overviewReport(state = {}, action) {
  switch (action.type) {
    case types.GET_OVERVIEW_REPORT_SUCCESS:
      // if (action.data && (Array.isArray(state) || typeof state !== 'object')) {
      return action.data;
    // } else {
    // return {};
    // }
    default:
      if (state === null || Array.isArray(state) || typeof state !== 'object') {
        return {};
      } else {
        return state;
      }
  }
}

export const retirementPercentagesDefault = {
  incomePercentage: 50,
  pensionPercentage: 50,
};

export function retirementPercentagesInputs(
  state = retirementPercentagesDefault,
  action
) {
  switch (action.type) {
    case types.SAVE_RETIREMENT_PERCENTAGES:
      if (
        action.data &&
        action.data.hasOwnProperty('incomePercentage') &&
        isOfTypeNumber(action.data.incomePercentage) &&
        action.data.hasOwnProperty('pensionPercentage') &&
        isOfTypeNumber(action.data.pensionPercentage)
      ) {
        return action.data;
      } else {
        return retirementPercentagesDefault;
      }
    case types.UPDATE_API_RETIREMENT_PERCENTAGES:
      if (
        Array.isArray(action.data) &&
        action.data.length > 0 &&
        action.data[0] &&
        action.data[0].hasOwnProperty('partTimePensionPercentage') &&
        action.data[0].hasOwnProperty('partTimeIncomePercentage')
      ) {
        const result = action.data[0];
        //For new users the API return is null
        const responseHasNullValues =
          result.partTimeIncomePercentage === null &&
          result.partTimePensionPercentage === null;

        if (responseHasNullValues) {
          break;
        }
        const retirementPercentagesInputs = {
          incomePercentage: result.partTimeIncomePercentage,
          pensionPercentage: result.partTimePensionPercentage,
        };
        return retirementPercentagesInputs;
      } else {
        if (
          state &&
          isEmptyObject(state) &&
          state.hasOwnProperty('incomePercentage') &&
          isOfTypeNumber(action.data.incomePercentage) &&
          state.hasOwnProperty('pensionPercentage') &&
          isOfTypeNumber(action.data.pensionPercentage)
        ) {
          return state;
        } else {
          return retirementPercentagesDefault;
        }
      }
    default:
      return state;
  }
}

export default combineReducers({
  selectedRetirementType,
  selectedRetirementYear,
  defaultRetirementAge,
  isRetirementVisible,
  selectedHighLow,
  scenarios,
  isCompletenessActive,
  monthOverview,
  selectedCurrentYear,
  isChartVisible,
  mortgageItem,
  rentItem,
  nibudItems,
  mortgageTypes,
  loanItem,
  loanTypes,
  aowItems,
  pensionItem,
  pensionTypes,
  insuranceItem,
  insuranceTypes,
  insuranceTaxTypes,
  incomeItem,
  incomeTypes,
  savingsItem,
  investmentsItem,
  houseItem,
  inheritanceItem,
  inheritanceTypes,
  houseHoldItem,
  shouldShowPrepension,
  overviewReport,
  retirementPercentagesInputs,
});
